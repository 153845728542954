class TabsHandler {
    constructor(tabButtonSelector, tabContentSelector) {
        this.tabButtons = document.querySelectorAll(tabButtonSelector);
        this.tabContents = document.querySelectorAll(tabContentSelector);

        if (this.tabButtons.length) {
            this.init();
        }
    }

    init() {
        this.tabButtons.forEach(button => {
            button.addEventListener("click", () => {
                const tabId = button.getAttribute("data-id");
                this.activateTab(tabId);
            });
        });

        // Activate the first tab by default
        const initialTab = this.tabButtons[0]?.getAttribute("data-id");
        if (initialTab) {
            this.activateTab(initialTab);
        }
    }

    activateTab(tabId) {
        this.tabButtons.forEach(button => button.classList.remove("active"));
        this.tabContents.forEach(content => content.style.display = "none");

        const activeTab = document.querySelector(`[data-id="${tabId}"]`);
        const activeContent = document.getElementById(tabId);

        if (activeTab && activeContent) {
            activeTab.classList.add("active");
            activeContent.style.display = "block";
        }
    }
}

export default TabsHandler