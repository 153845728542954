class NavbarToggler {
    constructor(togglerSelector, navigationSelector) {
        this.toggler = document.querySelector(togglerSelector);
        this.navigation = document.querySelector(navigationSelector);
        this.htmlTag = document.querySelector('html');

        if (this.toggler && this.navigation) {
            this.toggler.addEventListener("click", this.toggleNavbar.bind(this));
        }
    }

    toggleNavbar(event) {
        const target = event.currentTarget;
        target.classList.toggle("active");

        if (this.navigation) {
            this.navigation.classList.toggle("active");
            this.navigation.classList.toggle("open");
        }

        if (this.htmlTag) {
            this.htmlTag.classList.toggle("overflow-hidden");
        }
    }
}
export default NavbarToggler;