class StickyHeader {
    constructor(headerSelector) {
        this.header = document.querySelector(headerSelector);
        this.sticky = this.header ? this.header.offsetTop : 0;

        if (this.header) {
            this.init();
        }
    }

    init() {
        window.addEventListener("scroll", this.toggleSticky.bind(this));
    }

    toggleSticky() {
        if (window.pageYOffset > this.sticky) {
            this.header.classList.add("sticky");
        } else {
            this.header.classList.remove("sticky");
        }
    }
}

export default StickyHeader