export const deviceWidth = {
    mac14: 1400,
    mac13: 1300,
    desktop: 1200,
    ipadpro: 1024,
    ipad: 992,
    tablet: 767,
    mobile: 576,
}

// Page Loader
export const pageLoaderRef = {
    targetClass: ".loader-first",
    htmlClass: 'loadjs' // without dot
}

// SVG Converter
export const svgConverterRef = {
    targetClass: ".svg-convert",
    afterConvertClass: ".svg-converted"
}

// Responsive Handler
export const responsiveHandlerRef = {
    targetID: "portrait-warnning",
}


// MoreLessToggle
export const moreLessRef = {
    targetClass: '.toggle-more',
    activeClass: "active",
    clampClass: "line-clamp-1",
    closestParent: ".form-check-label",
    parentAlignmentClass: "d-flex"
}

// PhoneInputHandler

export const phoneHandlerRef = {
    targetClass: ".phoneiti",
}

// TabsHandler
export const tabModuleRef = {
    visibleProp: 'flex',
    attr: 'data-cards'
}

// FilterCardsbyCat
export const filterByCatRef = {
    targetActive: 'swiper-slide-active',
    allAlias: 'all',
    horLayoutClass: 'new-covered-card--horizontal',
    attr: 'data-ref',
    hideClass: 'd-none',
    nodataWrap: '.nodata-wrap'
}