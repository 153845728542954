import SwiperSlider from "./module/SwiperSlider";
import AccordianHandler from "./module/AccordianHandler";
import NavbarToggler from "./module/NavbarToggler";
import TabsHandler from "./module/TabsHandler";
import PageLoader from "./module/PageLoader";
import FontResizerController from "./module/FontResizerController";
import SVGConverter from "./module/SVGConverter";
import ResponsiveHandler from "./module/ResponsiveHandler";
import StickyHeader from "./module/StickyHeader"
import ShowHide from "./module/ShowHide";

// Generic Slider Module Create slider which contains the class swiper.
const swiperSliders = document.querySelectorAll(".swiper");
swiperSliders.forEach((item) => new SwiperSlider(item));
const footerMobileMenu = new AccordianHandler(".toggle-btn");
const navbarToggler = new NavbarToggler("#toggle", ".navigation");
const tabsHandler = new TabsHandler(".generic-tabs li", ".insights-slider");
const pageLoader = new PageLoader();
const desktopResponsive = new FontResizerController();
const svgConverterInstance = new SVGConverter();
const mobileLandscapeOff = new ResponsiveHandler();
const stickyHeader = new StickyHeader(".header");
const showHideSearchBar = new ShowHide('.search-wrapper', '.cross-icon');


document.querySelectorAll('.mobileToggle').forEach(toggle => {
  toggle.addEventListener('click', function () {
    // Remove active class and max-height from all other toggles and menus
    document.querySelectorAll('.mobileToggle').forEach(item => {
      if (item !== this) {
        item.classList.remove('active');
        const innerMenu = item.parentElement.querySelector('.inner-menu');
        if (innerMenu) {
          innerMenu.style.maxHeight = null;
        }
        item.parentElement.classList.remove('opened');
      }
    });

    // Toggle active class on the clicked toggle
    this.classList.toggle('active');

    // Get the `.inner-menu` element relative to the clicked toggle
    const innerMenu = this.parentElement.querySelector('.inner-menu');
    if (innerMenu) {
      if (innerMenu.style.maxHeight) {
        innerMenu.style.maxHeight = null; // Collapse the menu
        this.parentElement.classList.remove('opened');
      } else {
        innerMenu.style.maxHeight = innerMenu.scrollHeight + "px"; // Expand the menu
        this.parentElement.classList.toggle('opened');
      }
    }
  });
});


document.addEventListener('DOMContentLoaded', () => {
  const menuBtn = document.querySelector('.menu-btn');
  const megaMenu = document.querySelector('.mega-menu');
  const icon = menuBtn.querySelector('.icon svg'); // Target the SVG inside .icon

  if (menuBtn && megaMenu && icon) {
    menuBtn.addEventListener('click', () => {
      // Toggle 'active' class on menu-btn
      menuBtn.classList.toggle('active');

      // Toggle 'open' class on mega-menu
      megaMenu.classList.toggle('open');

      // Update SVG based on active state
      if (menuBtn.classList.contains('active')) {
        icon.innerHTML = `
          <path d="M1 1L14 14" stroke="#183B62" stroke-width="2"></path>
          <path d="M14 1L1 14" stroke="#183B62" stroke-width="2"></path>
        `;
        icon.setAttribute('viewBox', '0 0 15 15'); // Update viewBox for cross icon
      } else {
        icon.innerHTML = `
          <path d="M4.73353 0H0V4.36978H4.73353V0Z" fill="#183B62"></path>
          <path d="M15 0H10.2665V4.36978H15V0Z" fill="#183B62"></path>
          <path d="M4.73353 9.47637H0V13.8462H4.73353V9.47637Z" fill="#183B62"></path>
          <path d="M15 9.47637H10.2665V13.8462H15V9.47637Z" fill="#183B62"></path>
        `;
        icon.setAttribute('viewBox', '0 0 15 14'); // Reset viewBox for original icon
      }
    });
  }
});

document.querySelectorAll('.mobileToggle').forEach(toggle => {
  toggle.addEventListener('click', function () {
    // Check if the toggle is inside a container with the class 'mega-inner'
    if (this.closest('.mega-inner')) {
      // Remove active class and max-height from all other toggles and menus
      document.querySelectorAll('.mobileToggle').forEach(item => {
        if (item !== this) {
          item.classList.remove('active');
          const innerMenu = item.nextElementSibling;
          if (innerMenu) {
            innerMenu.style.maxHeight = null;
          }
        }
      });

      // Toggle active class on the clicked toggle
      this.classList.toggle('active');

      // Toggle max-height of the next sibling (inner-menu)
      const innerMenu = this.nextElementSibling;
      if (innerMenu) {
        if (innerMenu.style.maxHeight) {
          innerMenu.style.maxHeight = null; // Collapse the menu
          innerMenu.parentNode.classList.remove('opened');
        } else {
          innerMenu.style.maxHeight = innerMenu.scrollHeight + "px"; // Expand the menu
          innerMenu.parentNode.classList.add('opened');
        }
      }
    }
  });
});